
export default {
  name: 'AppSearchInput',
  data() {
    return {
      isInputFocus: false,
      search: '',
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      if (this.$route.query && this.$route.query.hasOwnProperty('s')) {
        this.search = this.$route.query.s || ''
      }
    },
    doSearch() {
      this.$router.push(
        '/search?s=' + this.search.trim() + '&t=' + new Date().getTime()
      )
    },
  },
}
