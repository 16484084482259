
export default {
  name: 'AppUpButton',

  methods: {
    handleScroll() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
