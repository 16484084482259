
import ContactCard from './ContactCard'
import CONTACTS_DATA from '@/mock/main/CONTACTS_DATA.json'

export default {
  name: 'ContactsBlock',
  components: { ContactCard },

  computed: {
    contacts() {
      return CONTACTS_DATA
    },
  },
}
