
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

export default {
  name: 'Sponsors',

  components: { Swiper, SwiperSlide },

  directives: {
    swiper: directive,
  },

  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 80,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
        },
        // Some Swiper option/callback...
      },
    }
  },
}
