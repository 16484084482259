
export default {
  name: 'ContactCard',

  props: {
    title: {
      type: String,
      default: '',
    },
    mailto: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
}
