
import ContactsBlock from './components/ContactsBlock'
import SertificateBlock from './components/SertificateBlock'
import Sponsors from './components/Sponsors'
import TitleBlock from './components/TitleBlock'
import Distributors from './components/Distributors'
import AppUpButton from '@/components/general/AppUpButton'
import AppSetsSection from '@/components/general/AppSetsSection'
import PRODUCTS from '@/mock/main/PRODUCTS.json'

export default {
  name: 'TheMain',
  components: {
    AppSetsSection,
    AppUpButton,
    ContactsBlock,
    SertificateBlock,
    // eslint-disable-next-line vue/no-unused-components
    Sponsors,
    TitleBlock,
    Distributors,
  },
  data() {
    return {
      datasetKeys: ['dna', 'genotyping'],
      currentOffset: false,
    }
  },
  computed: {
    productDatasets() {
      return PRODUCTS
    },
    isUpButtonShow() {
      return this.$mq === 'sm' && this.currentOffset
    },
  },
  mounted() {
    window.addEventListener(
      'scroll',
      () => (this.currentOffset = window.scrollY > 100)
    )
  },
}
